@import url('../../config/colors.css');
.projects{
    background-color:var(--ourProductsBackgroundColor) ;
    position :relative;
    padding-bottom: 20px;
}
.mainHeading{
    height: 90px;
    background-color: var(--ourProductsHeaderBackgroundColorAndCardHeaderBackground);
    display :flex;
    justify-content: center;
    align-items: center;
}
.hiddenMobileBackground {
    height: 40px;
    background-color: var(--ourProductsHeaderBackgroundColorAndCardHeaderBackground);
    position : absolute;
    width : 100%;
    top  : 90px;
  
}
.cards{
    margin  : auto;
    display : flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 15px;

    
}
@media screen  and (max-width : 800px){
    .hiddenMobileBackground{
        display : none;
    }
    
}



