@import url('../../config/colors.css');
.imageAndOffer{
    background-color: var(--imageProjectBackgroundColor);
    width : 100%;
    padding : 40px!important;
    display : flex;
    justify-content: space-between;
    flex-wrap: wrap;
}


.imageAndOfferImage{
    width : 100%;
    margin : auto;   
   
}

.columnImageAndOffer {
    margin : 1px;
    display :flex;
    justify-content: center;
    align-items: center;    
    width : 100%;
  

}
@media screen and (max-width: 716px) {
    .columnImageAndOffer {
            margin: 1px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin : auto;
    
    
        }
   
}
