.popupForm{
    padding : 20px;
}

.popupLogo{
    margin : auto;
    width : 100%;
    justify-items: center;
    text-align: center;
}
.dataHeading{
    text-align: center;
    font-size: 20px;
    margin-bottom: -20px;
    
}
.textHeading{
    text-align: center;
        font-size: 20px;
        font-weight: 600;
        margin:  6px 0 ;
        
}