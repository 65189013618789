.card{
    padding : 10px;
    background-color: var(--ourProductsHeaderTextColorAndCardBackground);
    margin : 10px;
}


.cardImage {
    position: relative;
    padding : 0;

}
.cardImage img{
    width : 100%;
    margin : 0;
}
.projectName {
    background-color: var(--ourProductsHeaderBackgroundColorAndCardHeaderBackground);
    color: var(--ourProductsHeaderTextColorAndCardBackground);
    text-align: center;
    position : absolute;
    bottom : 5px;
    width : 100%;
    font-size: 19px;
    font-weight: 440;
    padding : 5px 0;
}
.projectLocation  {
    color: var(--ourProductsHeaderTextColorAndCardBackground);
        text-align: center;
        width: 100%;
        font-size: 15px;
        background-color: var(--ourProductsCardAddressBackgroundAndHeadingColor);
       margin-top: -4px;
        padding: 4px 0;
}
.cardDetails{
    
}
.cardDetailsHeading{
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin : 10px 0;
    color: var(--ourProductsCardAddressBackgroundAndHeadingColor);
}

.cardDetailsValue{
    text-align: center;
    font-weight: 500;
    margin: 12px 0;
}
.homeHeistSchemeItem{
    margin : 10px 0;
    display :flex;
    justify-content: left;

}
.dot{
    width : 10px!important;
    height : 10px!important;
    border : 2px solid var(--ourProductsCardAddressBackgroundAndHeadingColor);
    border-radius  : 50%;
    margin-right: 10px;
    margin-top: 5px;

 


}
.homeHeistSchemeFlex{
    flex : 1;
}
.cardButton{
    border : 1px solid var(--ourProductsCardAddressBackgroundAndHeadingColor);
    width : 100%;
    padding : 10px 0;
    color : var(--ourProductsCardAddressBackgroundAndHeadingColor);
    font-weight: 500;
    font-size: 16px;
    background-color: transparent;
    cursor : pointer;
    margin-bottom: 0 10px ;
    

}