@import url('./../../config/colors.css');
.aboutthedevelopermainheading{
    color : var(--sideFormHighlightedColor);

}
.aboutTheDeveloperImage{
    border : 1px solid var(--sideFormHighlightedColor);
    width : 75px;
    height  : 75px;
    margin : auto;
}
.aboutTheDeveloperImage img {
    width : 100%;
    height  : 100%;
}