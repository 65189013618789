.inputForm {
    width : 100%;
    padding : 7px 5px;
    margin :4px 0;
    outline : none;

}
.submitButtonDiv{
    display :flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
}
.submitButtonDiv p{
    animation: pulsate 1s infinite;
        transition: 1s ease-in-out;
        font-size: 16px;
}

.submitButton{
    background-color: var(--sideFormHighlightedColor);
    padding : 13px 6px;
    border : none;
    font-size: 16px;
    transition : 0.3s ease;
    

}
.submitButton:hover {
    background-color: var(--sideFormHighlightedColorHover);
    padding : 13px 6px;
    border : none;
    font-size: 16px;
    transform : scale(1.0);

}

@keyframes pulsate {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}
 #smartDeveloper .entry-form .react-tel-input .form-control{
    border-radius : 0px!important;
    width : 100%!important;
    /* border : 1px solid black!important; */
}
#smartDeveloper .inputFormName{
    margin-bottom : -0.5px;
}
.form-control {
    width : 100%!important;
    border : none!important;
}
.phoneInputButton .form-control{
   border : 1px solid gray!important;
    margin : 3px 0 !important;
}
.phoneInputButton .flag-dropdown{
   
    margin : 3px 0 !important;
}