@import url('https://fonts.googleapis.com/css2?family=David+Libre:wght@400;500;700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.smartDeveloper>  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    scroll-behavior: smooth;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}