@import url('../../config/colors.css');
.desktopLogo{
    width : 100%;
    padding-bottom: 3px;
    
    border-bottom: 2px solid var(--navDividerColor);
}
.desktopMenuItems{
    color : var(--navItemColor);
    width : 100%;
    position : sticky;
    top : 10px;
    z-index: 3;
    height: 50px;
}
.navItem{
    padding : 8px 20px;
    color : var(--navItemColor);
    font-weight: 500;
    text-decoration: none;
}


.NavbarItems {
display : flex;
justify-content: space-between;
align-items: center;
}

.hamburger-react{
    color : var(--sideFormBackgroundColor)!important;
    font-size: 20px!important;
}
.MenuItems {
    list-style: none;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.nav-links {
    text-decoration: none;
   background-color: var(--sideFormBackgroundColor);
   color : white;
   border-top : 1px solid white;
   
  
}




.mobileNavbar{
    display : none;
}


@media screen and (max-width: 850px) {
    .mobileNavbar {
            display: none;
            display : block;
        }

        .desktopNavbar{
            display : none;
        }

    .NavbarItems {
        z-index: 99;
    }

    .MenuItems {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        height: auto;
        position: absolute;
        align-items: stretch;
        top: -510px;
        /* padding: 50px 0 40px 0; */
        margin: 0;
        z-index: -1;
        transition: all 0.3s ease-in-out;
        z-index: 2;
    }

    .MenuItems.active {
        top :85px;
    }

    .nav-links {
        display: block;
        width: 100%;
        padding: 17px 7px;
    }


}












