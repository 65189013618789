
:root{

   /* navbar */

    --navBackgroundColor : white;
    --navItemColor  : black;
    --navDividerColor : rgb(223,223,223);


    /* //sideForm */

    --sideFormBackgroundColor : rgb(14,86,168);
    --textColor : white;
    --sideFormBackgroundButtonColor : black;
    --sideFormHighlightedColor :rgb(16,162,3);
    --sideFormHighlightedColorHover :rgba(16, 162, 3, 0.737);
    --sideFormHighlightedColor2nd : rgb(197,25,105);
    --sideFormBorderColorOne : white;
    --sideFormBorderColorTwo : black;
    
    /* //priceSection */

    --priceBackgroundColor : black;
    --priceTextAndBottomBorderColor : white;


    /* //cardOption  */
    --cardBackgroundColor : rgb(7,19,58);
    --cardHeadingBackgroundAndHighlighterColor : rgb(255,1,0);
    --cardTextAndBorderColor : white;


    /* //ourProducts */
    --ourProductsHeaderBackgroundColorAndCardHeaderBackground : rgb(14,86,168);
    --ourProductsHeaderTextColorAndCardBackground :white;
    --ourProductsBackgroundColor : rgba(228,228,220);
    --ourProductsCardAddressBackgroundAndHeadingColor : rgb(16, 162, 3);
    --ourProductsCards : black;

    /* //imageprojects */
    --imageProjectBackgroundColor :rgba(6,20,70) ;
    --imageProjectMainHeadingBackgroundColor : rgba(255,1,0);
    --imageProjectTextColor : white;    
}