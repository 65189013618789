@import url('../../config/colors.css');

.sideForm{
    width : 700px;
    height: 560px;
    right: 30px;
    position: absolute;
    background-color: var(--sideFormBackgroundColor);
    top  : 30px;
    color : var(--textColor);
    border-radius : 10px;
    padding : 10px;
}
.sideForm2 {
    margin-top: 12px;
}
.sideFormHeading{
    width : 80%;
    margin : auto;
}
.sideFormHeading h1{
    text-align: center;
    font-size: 24px;
    color: var(--textColor);
}
.animatingHighlightingCard{
    display :flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    letter-spacing: 3px;
    padding : 10px 0;
    font-size : 16px;
    background-color: var(--sideFormHighlightedColor);
    animation: pulsate 1s infinite;
    transition: 1s ease-in-out;
}

@keyframes pulsate {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}
.animatingHighlightingCard span img{
    width : 20px;
    height : 20px;
    margin-right: 10px;
}
.sideForm2ndEntry{
    background-color: var(--sideFormHighlightedColor2nd);
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        padding: 5px 0;
        text-align: center;

    }
    .sideForm2ndEntry div{   
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 2px;
    margin : 2px 0;
}
 .sideForm2ndEntry p{
    font-size: 14px;
    letter-spacing: 2px;
    margin : 7px 0;
}
.sideForm3rdEntry{
    background-color: var(--sideFormHighlightedColor);
}
.getInTouchFormHeading{
    display : flex;
    justify-content: center;
    align-items: center;
margin : 20px 0;
letter-spacing: 2px;
font-size: 17px;

}
.getInTouchFormHeading img {
    width : 15px;
    height : 15px;
    margin-right: 5px;
    font-size: 25px;
}

@media screen and (max-width : 1000px) {
.sideForm {
        width: 100%;
        height: auto;
       position : initial;
        background-color: var(--sideFormBackgroundColor);
        color: var(--textColor);
        border-radius: 0px;
        padding: 10px;
    }
    
}