.hero {
    height : auto;
    position : relative;
    margin-bottom: 1px;
}
.slick-dots ,.slick-dots-bottom{
    left : 10px!important;
    bottom : 40px!important;

}
.slick-slide img{
    height :84vh;
}
@media screen and (max-width :900px) {
    .slick-slide img {
            height: 400px;
        }
    
}