.highLightedCard{

margin : 20px 0;

}
.highLightedCard h2{
    background-color: var(--imageProjectMainHeadingBackgroundColor);
    color : var(--imageProjectTextColor);
    word-spacing: 1px;
    text-align: center;
    padding : 5px 0;
    font-size: 26px;
}
.highlightedCardPoint{
    display : flex;
    justify-content: center;
    align-items: center;
    display :flex;
}
.pointHighlight {
    margin: 15px;
    transform: rotate(50deg);
    width  : 10px;
    height : 10px;
    background-color: var(--imageProjectMainHeadingBackgroundColor);
}
.pointItem {
    color : var(--imageProjectTextColor);
    flex : 1;
}